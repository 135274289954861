import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import createDebouncedAsyncThunk from 'lib/store/create-debounced-async-thunk';
import { addCartItem, updateCartItem } from '../cart/cart-slice';
import {
  createCheckoutSession as createCheckoutSessionApi,
  createEncryptedFastSpringCheckoutSession as createEncryptedFastSpringCheckoutSessionApi,
  completeFastSpringCheckoutSession as completeFastSpringCheckoutSessionApi,
  getPaymentMethods as getPaymentMethodsApi,
  savePaymentMethod as savePaymentMethodApi,
  placeOrder as createOrderApi,
  updateCheckout as updateCheckoutApi,
} from './checkout-api';
import { deleteCheckoutId, saveCheckoutId } from './checkout-provider';
import { CheckoutData, FastSpringEncryptedData, Source } from './checkout-models';
import { deleteStoredCartId } from 'components/cart/cart-provider';
export interface InitialStateModel {
  checkoutData: CheckoutData | null;
  checkoutDataStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  paymentSessionId: string;
  paymentMethods: Source[];
  paymentMethodStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  selectedSource: CustomSource | null;
  createOrderStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateCheckoutStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateCheckoutError: string | null;
  checkoutId: string | null;
  checkoutStage:
    | 'sign-in'
    | 'payment-billing'
    | 'payment-method'
    | 'confirmation'
    | 'mp-paypal-billing'
    | 'mp-checkout-summary';
  billingAddress: PaymentAddress | null;
  shippingAddress: PaymentAddress | null;
  error: string | null;
  defaultPaymentMethodId: string;
  fastSpringCheckoutStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  fastSpringStoreBuilderKeys: FastSpringEncryptedData | null;
  salesforceOrderId: string | null;
  fastSpringTaxData: FastSpringCallbackData | null;
  fastSpringEmbeddedStoreStatus: 'idle' | 'loading' | 'succeeded';
  eulaTermsAccepted: boolean;
}

export type PaymentAddress = {
  firstName: string;
  lastName: string;
  line1: string;
  line2?: string;
  city?: string;
  state?: string;
  postalCode: string;
  country: string;
  phoneNumberExtension?: string;
  phoneNumber: string;
};

export type FastSpringContactAddress = {
  email: string | null | undefined;
  firstName: string;
  lastName: string;
  phone: string;
  addressLine1: string;
  addressLine2: string | undefined;
  city: string | undefined;
  region: string | undefined;
  country: string;
  postalCode: string;
};

export type FastSpringCallbackData = {
  totalValue: number;
  taxValue: number;
  totalWithTaxValue: number;
  subtotalValue: number;
  groups: {
    items: {
      selected: boolean;
    }[];
  }[];
};

const initialState: InitialStateModel = {
  checkoutData: null,
  checkoutDataStatus: 'idle',
  paymentSessionId: '',
  paymentMethods: [],
  paymentMethodStatus: 'idle',
  selectedSource: null,
  createOrderStatus: 'idle',
  updateCheckoutStatus: 'idle',
  updateCheckoutError: null,
  checkoutId: null,
  checkoutStage: 'payment-billing',
  billingAddress: null,
  shippingAddress: null,
  error: null,
  defaultPaymentMethodId: '',
  fastSpringCheckoutStatus: 'idle',
  fastSpringStoreBuilderKeys: null,
  salesforceOrderId: null,
  fastSpringTaxData: null,
  fastSpringEmbeddedStoreStatus: 'idle',
  eulaTermsAccepted: false,
};

export const getPaymentMethods = createDebouncedAsyncThunk(
  'checkout/getPaymentMethods',
  async (data: { bearerToken: string }) => await getPaymentMethodsApi(data.bearerToken),
  150
);

export const savePaymentMethod = createDebouncedAsyncThunk(
  'checkout/savePaymentMethod',
  async (data: { sourceId: string; bearerToken: string }) =>
    await savePaymentMethodApi(data.sourceId, data.bearerToken),
  150
);

export const placeOrder = createDebouncedAsyncThunk(
  'checkout/placeOrder',
  async (data: { checkoutId: string; sourceId: string; cartId: string; bearerToken: string }) =>
    await createOrderApi(data.checkoutId, data.cartId, data.bearerToken),
  150
);

export const createCheckoutSession = createDebouncedAsyncThunk(
  'checkout/createCheckoutSession',
  async (data: { store: string; sourceId?: string; bearerToken: string }) =>
    await createCheckoutSessionApi(data.store, data?.sourceId, data.bearerToken),
  150
);

export const createEncryptedFastSpringCheckoutSession = createDebouncedAsyncThunk(
  'checkout/createEncryptedFastSpringCheckoutSession',
  async (data: { billingDetails: FastSpringContactAddress; store: string; bearerToken: string }) =>
    await createEncryptedFastSpringCheckoutSessionApi(
      data.billingDetails,
      data.store,
      data.bearerToken
    ),
  150
);

export const completeFastSpringCheckoutSession = createDebouncedAsyncThunk(
  'checkout/completeFastSpringCheckoutSession',
  async (data: { store: string; bearerToken: string }) =>
    await completeFastSpringCheckoutSessionApi(data.store, data.bearerToken),
  150
);

export const updateCheckout = createDebouncedAsyncThunk(
  'checkout/updateCheckout',
  async (data: {
    checkoutId: string;
    sourceId: string;
    bearerToken: string;
    currencyCode?: string;
    cartId?: string;
  }) => {
    return await updateCheckoutApi(
      data.checkoutId,
      data.sourceId,
      data.bearerToken,
      data.cartId,
      data.currencyCode
    );
  },
  150
);

export type CustomSource = {
  source: Source;
  isNew: boolean;
  forceSave?: boolean;
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setSelectedSource: (state, action: PayloadAction<CustomSource | null>) => {
      state.updateCheckoutError = null;
      state.selectedSource = action.payload;
    },
    setBillingAddress: (state, action: PayloadAction<PaymentAddress | null>) => {
      state.billingAddress = action.payload;
    },
    setCheckoutStage: (
      state,
      action: PayloadAction<
        | 'payment-billing'
        | 'mp-paypal-billing'
        | 'payment-method'
        | 'confirmation'
        | 'mp-checkout-summary'
      >
    ) => {
      state.checkoutStage = action.payload;
    },
    removeCheckoutSession: (state) => {
      state.updateCheckoutStatus = 'succeeded';
      state.checkoutData = null;
      state.checkoutDataStatus = 'idle';
      // state.paymentSessionId = action.payload.data?.digitalRiverResponse?.payment?.session?.id;
      state.checkoutId = null;
      deleteCheckoutId();
    },
    setFastSpringEmbeddedStoreStatus: (
      state,
      action: PayloadAction<'idle' | 'loading' | 'succeeded'>
    ) => {
      state.fastSpringEmbeddedStoreStatus = action.payload;
    },
    setFastSpringTaxData: (state, action: PayloadAction<FastSpringCallbackData>) => {
      state.fastSpringTaxData = action.payload;
    },
    setEulaTermsAccepted: (state, action: PayloadAction<boolean>) => {
      state.eulaTermsAccepted = action.payload;
    },
    updateCheckoutError: (state, action: PayloadAction<string | null>) => {
      state.updateCheckoutError = action.payload;
      state.error = action.payload;
    },
    removeFastSpringStoreBuilderKeys: (state) => {
      state.fastSpringStoreBuilderKeys = null;
      state.fastSpringEmbeddedStoreStatus = 'idle';
      state.fastSpringCheckoutStatus = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createCheckoutSession.pending, (state) => {
        state.checkoutDataStatus = 'loading';
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.checkoutData = action.payload.data;
        state.checkoutDataStatus = 'succeeded';
        state.paymentSessionId = action.payload.data?.digitalRiverResponse?.payment?.session?.id;

        state.checkoutId = action.payload.data?.digitalRiverResponse?.id;
        saveCheckoutId(action.payload.data?.digitalRiverResponse?.id);
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.checkoutDataStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(createEncryptedFastSpringCheckoutSession.pending, (state) => {
        state.fastSpringCheckoutStatus = 'loading';
        state.fastSpringStoreBuilderKeys = null;
      })
      .addCase(createEncryptedFastSpringCheckoutSession.fulfilled, (state, action) => {
        state.fastSpringStoreBuilderKeys = action.payload;
        state.fastSpringCheckoutStatus = 'succeeded';
        state.error = null;
      })
      .addCase(createEncryptedFastSpringCheckoutSession.rejected, (state) => {
        state.fastSpringCheckoutStatus = 'failed';
        state.fastSpringStoreBuilderKeys = null;
        state.error = 'Something went wrong';
      })
      .addCase(completeFastSpringCheckoutSession.pending, (state) => {
        state.fastSpringCheckoutStatus = 'loading';
      })
      .addCase(completeFastSpringCheckoutSession.fulfilled, (state, action) => {
        state.fastSpringCheckoutStatus = 'succeeded';
        state.salesforceOrderId = action.payload.data.sfOrderNumber;
        deleteStoredCartId();
      })
      .addCase(completeFastSpringCheckoutSession.rejected, (state) => {
        state.fastSpringCheckoutStatus = 'failed';
        state.error = 'Something went wrong';
      })
      .addCase(getPaymentMethods.pending, (state) => {
        state.paymentMethodStatus = 'loading';
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethodStatus = 'succeeded';
        if (action.payload.data?.digitalRiverResponse?.sources != null) {
          const sortedSources = action.payload.data.digitalRiverResponse.sources.sort((a, b) => {
            return new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime();
          });
          state.paymentMethods = sortedSources;
          state.defaultPaymentMethodId = action.payload.data.digitalRiverResponse.defaultSourceId;
        }
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.paymentMethodStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';
      })
      .addCase(placeOrder.pending, (state) => {
        state.createOrderStatus = 'loading';
      })
      .addCase(placeOrder.fulfilled, (state) => {
        deleteCheckoutId();
        state.createOrderStatus = 'succeeded';
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.createOrderStatus = 'failed';
        state.error = action.error.message || 'Something went wrong';

        deleteCheckoutId();
        state.paymentSessionId = '';
        state.checkoutId = null;
        state.selectedSource = null;
      })
      .addCase(updateCheckout.pending, (state) => {
        state.checkoutDataStatus = 'loading';
        state.updateCheckoutStatus = 'loading';
        state.updateCheckoutError = null;
      })
      .addCase(updateCheckout.fulfilled, (state, action) => {
        if (action.payload == null) {
          return;
        }

        state.updateCheckoutStatus = 'succeeded';
        state.checkoutData = action.payload.data;
        state.checkoutDataStatus = 'succeeded';
        state.paymentSessionId = action.payload.data?.digitalRiverResponse?.payment?.session?.id;

        state.checkoutId = action.payload.data?.digitalRiverResponse?.id;
        saveCheckoutId(action.payload.data?.digitalRiverResponse?.id);
      })
      .addCase(updateCheckout.rejected, (state, action) => {
        state.updateCheckoutStatus = 'failed';
        state.checkoutDataStatus = 'idle';
        state.updateCheckoutError = action.error.message || 'Something went wrong';

        deleteCheckoutId();
        state.paymentSessionId = '';
        state.checkoutId = null;
        state.selectedSource = null;
      })
      .addCase(addCartItem.fulfilled, (state) => {
        state.checkoutId = null;
      })
      .addCase(updateCartItem.fulfilled, (state) => {
        state.checkoutId = null;
      });
  },
});

export const {
  setCheckoutStage,
  setBillingAddress,
  setSelectedSource,
  removeCheckoutSession,
  setFastSpringTaxData,
  setFastSpringEmbeddedStoreStatus,
  setEulaTermsAccepted,
  updateCheckoutError,
  removeFastSpringStoreBuilderKeys,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
